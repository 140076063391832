<template>
  <PageContentContainer>
    <h1 class="text-5xl mb-2">{{ $t('home.welcome') }}, {{ user.displayName }}</h1>
    <i18n-t tag="p" keypath="home.intro" scope="global">
      <template v-slot:name>{{ CTF_TITLE }}</template>
      <template v-slot:discordLink>
        <a target="_blank" :href="CTF_DISCORD_SERVER_ADDRESS">Discord</a>
      </template>
    </i18n-t>
    <template v-if="!metadata.ctfHasStarted">
      <p class="mt-4 text-lg">
        <strong>{{ $t('home.notYetStarted') }}</strong>
      </p>
      <CountDown class="my-8" :date="metadata.ctfStart" />
      <img :src="imgUrl" class="mx-auto my-4" alt="Meme about Pablo Escobar being sad that PCG did not start, yet.">
    </template>
    <ScoreGroupSelector v-else-if="!user.hasSelectedGroup" @update-user-info="refetchUser" />
    <template v-else-if="metadata.isDuringPlayTime">
      <div class="mt-4">
        <i18n-t tag="p" keypath="home.connectToVPN" scope="global">
          <template v-slot:vpn>
            <strong>{{ $t('home.vpn') }}</strong>
          </template>
        </i18n-t>
        <i18n-t v-if="metadata.isFlugzeugEnabled" tag="p" keypath="home.flugzeugHint" scope="global">
          <template v-slot:flugzeugLink>
            <RouterLink :to="{name: 'flugzeug'}">{{ $t('home.flugzeug') }}</RouterLink>
          </template>
        </i18n-t>
      </div>

      <!-- TODO: Replace for other CTFs -->
      <div class="step">
        <div class="step-icon">
          <QuestionMarkCircleIcon />
        </div>
        <div class="step-description">
          <h3>{{ $t('home.howToTitle') }}</h3>
          <MarkdownContent :markdown="$t('home.howToMd')" />
        </div>
      </div>
    </template>
    <template v-else>
      <div class="mt-4">
        <p class="text-3xl font-bold">
          <strong>{{ $t('home.ctfEnded') }}</strong>
        </p>
      </div>
    </template>
  </PageContentContainer>
</template>

<script setup lang="ts">
  import { QuestionMarkCircleIcon } from '@heroicons/vue/24/outline'

  import MarkdownContent from '@/components/MarkdownContent'
  import PageContentContainer from '@/components/PageContentContainer.vue'
  import ScoreGroupSelector from '@/components/ScoreGroupSelector.vue'
  import CountDown from '@/components/landing-page/CountDown.vue'
  import { CTF_DISCORD_SERVER_ADDRESS, CTF_TITLE } from '@/constants'
  import { useUser, useMetadata } from '@/hooks'
  import imgUrl from '@/images/waiting-for-start.jpg'

  const { metadata } = useMetadata()
  const { user, refetch: refetchUser } = useUser()
</script>

<style scoped lang="scss">
  .step {
    @apply flex my-5;

    .step-icon {
      @apply py-1 mr-3 h-7 w-7 flex-none;
    }

    .step-description {
      @apply flex-1;

      h3 {
        @apply font-bold text-xl mt-1 mb-2;
      }

      .os-selector {
        @apply flex items-center justify-center py-3;

        button {
          @apply inline-block px-4 py-1 border-2;

          // Inactive styles
          @apply bg-accentBg hover:bg-accentHover border-gray-600;

          &.active {
            @apply bg-primaryBg hover:bg-primaryBgHover border-primary;
          }
        }
      }
    }
  }
</style>
